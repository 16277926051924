import { Flex, Skeleton } from 'antd';
import { createRepo } from 'api/firebase';
import {
  addClinician,
  addPatient,
  clinicActionAllowPatientAssessments,
  clinicActionDisablePatientAssessments,
  clinicActionEditClinicianDetails,
  clinicActionEditPatientDetails,
  clinicActionResendInvitation,
  listClinicians,
  listPatients,
  ListUserAPIOptions
} from 'api/firebase/firebase-api';
import { useAppSelector } from 'app/rootReducer';
import { PrintableRootPage } from 'components/page/page-2';
import { Clinician } from 'documents/clinician';
import { Collection } from 'documents/document';
import { Patient } from 'documents/patient';
import { User } from 'features/auth/authSlice';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { isNil, omitBy, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { ActionState } from './action-tag-component';
import { ActionTagEditDetails } from './action-tag-edit-details';
import {
  BASE_USER_ADD_QUESTIONS,
  FormQuestion
} from './user-add-modal-component';
import UserList from './user-list-component';

export default function ClinicUserManagement() {
  const { user, isUserDataLoading } = useAppSelector(s => s.auth);
  const [clinicId, setClinicId] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    async function setup(user: User | null, isUserDataLoading: boolean) {
      if (isUserDataLoading) return;
      if (!user) throw new Error(`You must be logged in`);

      const clinicianRepo = createRepo<Clinician>(Collection.Clinicians);
      const clinicianInfo = await clinicianRepo.find(user.uid);
      if (!clinicianInfo)
        throw new Error(`Clinician (id=${user.uid}) not found`);
      clinicianInfo?.clinics[0];
      setClinicId(clinicianInfo?.clinics[0]);
    }
    setup(user, isUserDataLoading)
      .then(() => {
        setError(null);
      })
      .catch(error => {
        setError(resolveError(error));
      });
  }, [user, isUserDataLoading]);

  const [patientUserAddQuestions, setPatientUserAddQuestions] = useState<
    FormQuestion[]
  >([
    ...BASE_USER_ADD_QUESTIONS,
    {
      type: 'select',
      name: 'clinicianId',
      label: 'Clinician',
      rules: [{ required: true }, { type: 'enum', enum: [] }],
      options: [],

      // Disable the select until clinician data is ready to list
      loading: true,
      disabled: true
    }
  ]);
  useEffect(() => {
    async function getClinicians(clinicId: string) {
      const result = await listClinicians({
        clinicId
      });

      const clinicians = [
        ...result.data.inClinic,
        ...result.data.pendingInClinic
      ];
      const options = uniqBy(
        clinicians.map(_ => ({
          label: `${_.displayName} - ${_.id}`,
          value: _.id
        })),
        _ => _.value
      );
      // Set the clinicians list to be available in the patients add-user options
      setPatientUserAddQuestions([
        ...BASE_USER_ADD_QUESTIONS,
        {
          type: 'select',
          name: 'clinicianId',
          label: 'Clinician',
          rules: [
            { required: true },
            { type: 'enum', enum: options.map(_ => _.value) }
          ],
          options
        }
      ]);
    }

    if (!clinicId) return;
    getClinicians(clinicId);
  }, [clinicId]);

  return (
    <PrintableRootPage title="User management">
      <Flex vertical gap={80}>
        {clinicId && (
          <>
            <div>
              <UserList<Patient>
                typeUnit="Patient"
                apiAddUser={(user, clinicId) =>
                  addPatient({ ...(user as any), clinicId })
                }
                api={({ clinicId, lastUserId, lastPendingUserId }) =>
                  listPatients({
                    clinicId,
                    usersLimit: 10,
                    pendingUsersLimit: 10,
                    ...(lastPendingUserId && {
                      pendingUsersLastDocId: lastPendingUserId
                    }),
                    ...(lastUserId && { usersLastDocId: lastUserId })
                  })
                }
                clinicId={clinicId}
                actions={[
                  {
                    name: p =>
                      p.raw.canStartAssessments
                        ? 'Activated'
                        : 'Activate Assessment',
                    onClick: async p => {
                      if (p.raw.id === undefined)
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      return clinicActionAllowPatientAssessments(p.raw.id);
                    },
                    shouldShow: p => !p.invitePending,
                    initialState: p =>
                      p.raw.canStartAssessments
                        ? ActionState.SUCCESS
                        : ActionState.NEUTRAL,
                    toolTip: p =>
                      p.raw.canStartAssessments
                        ? `This patient can start new assessments`
                        : `Click to allow this patient to start new assessments`
                  },
                  {
                    name: p =>
                      !p.raw.canStartAssessments
                        ? '[NOT SHOWN IN PRODUCTION] Disabled'
                        : '[NOT SHOWN IN PRODUCTION] Disable Assessment',
                    onClick: async p => {
                      if (p.raw.id === undefined)
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      return clinicActionDisablePatientAssessments(p.raw.id);
                    },
                    shouldShow: p =>
                      ['local', 'development', 'qa'].includes(
                        process.env.REACT_APP_CONCUSSIONRX_DEPLOYMENT as string
                      ) && !p.invitePending,
                    initialState: p =>
                      !p.raw.canStartAssessments
                        ? ActionState.SUCCESS
                        : ActionState.NEUTRAL,
                    toolTip: p =>
                      p.raw.canStartAssessments
                        ? `This patient cannot start new assessments`
                        : `Click to disable this patient from starting new assessments`
                  },
                  {
                    name: 'Resend invitation',
                    shouldShow: p => p.invitePending,
                    initialState: () => ActionState.NEUTRAL,
                    onClick: p => {
                      if (!p.raw.id)
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      return clinicActionResendInvitation(p.raw.id);
                    }
                  },
                  {
                    name: 'Edit details',
                    initialState: () => ActionState.NEUTRAL,
                    render: props => (
                      <ActionTagEditDetails
                        {...props}
                        userAddQuestions={patientUserAddQuestions}
                      />
                    ),
                    onClick: p => {
                      if (!p.raw.id) {
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      }

                      return clinicActionEditPatientDetails(
                        p.raw.id,
                        p.firstName,
                        p.lastName,
                        p.email
                      );
                    },
                    onSuccess: {
                      blockOnDone: false,
                      timeout: 1000
                    }
                  }
                ]}
                userAddQuestions={patientUserAddQuestions}
              />
            </div>
            <div>
              <UserList<Clinician>
                typeUnit="Clinician"
                apiAddUser={(user, clinicId) =>
                  addClinician({ ...user, clinicId })
                }
                api={({ clinicId, lastUserId, lastPendingUserId }) =>
                  listClinicians(
                    omitBy(
                      {
                        clinicId,
                        pendingUsersLimit: 10,
                        usersLimit: 10,
                        pendingUsersLastDocId: lastPendingUserId,
                        usersLastDocId: lastUserId
                      },
                      isNil
                    ) as any as ListUserAPIOptions
                  )
                }
                clinicId={clinicId}
                actions={[
                  {
                    name: 'Edit details',
                    initialState: () => ActionState.NEUTRAL,
                    render: props => (
                      <ActionTagEditDetails
                        {...props}
                        userAddQuestions={BASE_USER_ADD_QUESTIONS}
                      />
                    ),
                    onClick: p => {
                      if (!p.raw.id) {
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      }

                      return clinicActionEditClinicianDetails(
                        p.raw.id,
                        p.firstName,
                        p.lastName,
                        p.email
                      );
                    }
                  },
                  {
                    name: 'Resend invitation',
                    shouldShow: p => p.invitePending,
                    initialState: () => ActionState.NEUTRAL,
                    onClick: p => {
                      if (!p.raw.id)
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      return clinicActionResendInvitation(p.raw.id);
                    }
                  }
                ]}
                userAddQuestions={BASE_USER_ADD_QUESTIONS}
              />
            </div>
          </>
        )}
        {!clinicId && !error && <Skeleton active />}
        {error && (
          <ErrorDisplay
            title="Something went wrong when loading clinician information"
            description={`Please report this error to contact@concussionrx.com`}
            error={error}
          />
        )}
      </Flex>
    </PrintableRootPage>
  );
}
